// global helper functions

import imageUrlBuilder from "@sanity/image-url"
import axios from "axios"
import queryString from "querystring"

// reformat graphql response to get a plain array with objects (instead of edges and nodes)
export const getNodesFromQuery = dataSet => {
  // console.log("DataSet:", dataSet)
  return dataSet.edges.map(element => {
    return element.node
  })
}

// constructor for image URLs, see documentation
const builder = imageUrlBuilder({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATA_SET,
})

// image URL creator, see documentation
export function imageUrlFor(source) {
  return builder.image(source)
}

// dynamic request to preview drafts before publishing
export const getPreviewData = props => {
  const { isDraft, pageId } = queryString.parse(props.location.search)
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
    },
  }
  return axios
    .get(
      `https://${
        process.env.GATSBY_SANITY_PROJECT_ID
      }.api.sanity.io/v1/data/doc/${process.env.GATSBY_SANITY_DATA_SET}/${
        isDraft ? "drafts." : ""
      }${pageId}`,
      config
    )
    .then(doc => {
      return doc.data.documents[0]
    })
    .catch(err => console.error(err))
}
