import { propTypes } from "@sanity/block-content-to-react"
import React from "react"
import styled from "styled-components"

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.align};
`

const CTAButton = styled.button`
  margin: 10px 0;
  border: none;
  background-color: transparent;
  a,
  span {
    width: ${props => (props.fullWidth ? "100%" : "300px")};
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.lightText}!important;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      ${props => props.theme.altAccent};
    border: 2px solid ${props => props.theme.altAccent};
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    &:hover {
      background-color: ${props => props.theme.altAccent};
      border: 2px solid ${props => props.theme.lightText};
    }
  }
`

// CTA component
const Cta = ({ fullWidth, href, children, onClick, type, className }) => {
  return (
    <ButtonContainer align="center">
      <CTAButton
        fullWidth={fullWidth}
        type={type}
        onClick={onClick}
        className={className}
      >
        {href ? <a href={href}>{children}</a> : <span>{children}</span>}
      </CTAButton>
    </ButtonContainer>
  )
}

export default Cta
